body {
  color: rgb(var(--bs-tertiary-color-rgb));
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.carousel-caption {
  z-index: 10;
  bottom: 3rem;
}

.marketing .col-lg-4 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.marketing .col-lg-4 p {
  margin-left: .75rem;
  margin-right: .75rem;
}

.featurette-divider {
  margin: 2rem 0;
}

.featurette-heading {
  letter-spacing: -.05rem;
}

@media (width >= 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (width >= 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
/*# sourceMappingURL=contact.8bf2622b.css.map */
